<template>
  <div class="svgdiv">

<!--logout_icon.svg -->
<svg  xmlns="http://www.w3.org/2000/svg" width="16.396" height="16.342" viewBox="0 0 16.396 16.342"><defs></defs><g transform="translate(0 -0.85)"><g transform="translate(0 0.85)"><path class="a" d="M8.171,15.83H2.043a.681.681,0,0,1-.681-.681V2.893a.681.681,0,0,1,.681-.681H8.171a.681.681,0,0,0,0-1.362H2.043A2.045,2.045,0,0,0,0,2.893V15.149a2.045,2.045,0,0,0,2.043,2.043H8.171a.681.681,0,1,0,0-1.362Z" transform="translate(0 -0.85)"/></g><g transform="translate(5.447 4.255)"><path class="a" d="M180.846,111.446l-4.14-4.086a.681.681,0,1,0-.956.97l2.959,2.92h-7.928a.681.681,0,0,0,0,1.362h7.928l-2.959,2.92a.681.681,0,1,0,.956.97l4.14-4.085a.681.681,0,0,0,0-.97Z" transform="translate(-170.1 -107.165)"/></g></g></svg>
<!--logout_icon_vlite.svg-->
<!-- <svg v-else-if="vendorid === 'rcvQ7Koa'" xmlns="http://www.w3.org/2000/svg" width="16.396" height="16.342" viewBox="0 0 16.396 16.342"><defs></defs><g transform="translate(0 -0.85)"><g transform="translate(0 0.85)"><path class="a" d="M8.171,15.83H2.043a.681.681,0,0,1-.681-.681V2.893a.681.681,0,0,1,.681-.681H8.171a.681.681,0,0,0,0-1.362H2.043A2.045,2.045,0,0,0,0,2.893V15.149a2.045,2.045,0,0,0,2.043,2.043H8.171a.681.681,0,1,0,0-1.362Z" transform="translate(0 -0.85)"/></g><g transform="translate(5.447 4.255)"><path class="a" d="M180.846,111.446l-4.14-4.086a.681.681,0,1,0-.956.97l2.959,2.92h-7.928a.681.681,0,0,0,0,1.362h7.928l-2.959,2.92a.681.681,0,1,0,.956.97l4.14-4.085a.681.681,0,0,0,0-.97Z" transform="translate(-170.1 -107.165)"/></g></g></svg> -->
<!--logout_icon_ok.svg -->
<!-- <svg v-else-if="vendorid === 'Fx93mhiV'" xmlns="http://www.w3.org/2000/svg" width="16.396" height="16.342" viewBox="0 0 16.396 16.342"><defs></defs><g transform="translate(0 -0.85)"><g transform="translate(0 0.85)"><path class="a" d="M8.171,15.83H2.043a.681.681,0,0,1-.681-.681V2.893a.681.681,0,0,1,.681-.681H8.171a.681.681,0,0,0,0-1.362H2.043A2.045,2.045,0,0,0,0,2.893V15.149a2.045,2.045,0,0,0,2.043,2.043H8.171a.681.681,0,1,0,0-1.362Z" transform="translate(0 -0.85)"/></g><g transform="translate(5.447 4.255)"><path class="a" d="M180.846,111.446l-4.14-4.086a.681.681,0,1,0-.956.97l2.959,2.92h-7.928a.681.681,0,0,0,0,1.362h7.928l-2.959,2.92a.681.681,0,1,0,.956.97l4.14-4.085a.681.681,0,0,0,0-.97Z" transform="translate(-170.1 -107.165)"/></g></g></svg> -->
  
</div>
  
  

  

  
</template>

<script>
export default {
  // props:["vendorid"]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
.a {
  fill: $theme-primary !important;
  fill-rule: evenodd;
}

</style>